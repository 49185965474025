<template>
  <v-dialog max-width="600px" v-model="export_modal" persistent>
    <v-sheet class="pa-3">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <!--Close Button-->
            <v-btn @click="$emit('closeModal')" small icon class="float-right"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
            <h3>Export Income Statement</h3></v-col
          >
        </v-row>
        <!--Expansion Panels-->
        <v-row>
          <v-col cols="12">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in export_items" :key="i">
                <v-expansion-panel-header>
                  {{ item.name }}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <component :is="item.component" :months="months" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>
<script>
export default {
  name: "IncomeStatementExportModal",
  props: ["export_modal", "months"],
  components: {
    ExportSingleMonthCard: () => import("../Cards/ExportSingleMonthCard.vue"),
    ExportDateRangeCard: () => import("../Cards/ExportDateRangeCard.vue"),
  },
  data() {
    return {
      export_items: [
        {
          name: "Single Month",
          component: "ExportSingleMonthCard",
        },
        {
          name: "Date Range",
          component: "ExportDateRangeCard",
        },
      ],
    };
  },
};
</script>